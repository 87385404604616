import React, {useContext, useState} from 'react';
import {Link,  useNavigate } from 'react-router-dom';
import styles from './Header.module.css';
import reverseLogo from '../assets/reverse_image.png';
import LoginModal from './LoginModal';
import profileImage from '../assets/icon-user-profile.png';
import { AuthContext } from '../contexts/AuthContext';
import ProfileToggle from "./ProfileToggle";
import PreparationModal from "./PreparationModal";

const Header = () => {
    const navigate = useNavigate();
    const { auth, setAuth } = useContext(AuthContext);
    const handleLogout = () => {
        // 쿠키 삭제
        document.cookie = 'Authorization=; Max-Age=0; path=/; domain=.go-reverse.site;' ;
        // 상태 관리 삭제
        setAuth({ isLoggedIn: false, memberName: null, memberId: null });
        // 리다이렉트
        navigate("/");
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPreparationModalOpen, setIsPreparationModalOpen] = useState(false);
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const openPreparationModal = (event) => {
        event.preventDefault();
        setIsPreparationModalOpen(true);
    };

    const closePreparationModal = () => {
        setIsPreparationModalOpen(false);
        navigate(-1);
    };

    return (
        <>
            <header className={styles.header}>
                <div className={styles.logoContainer}>
                    <Link to="/reverse">
                        <img src={reverseLogo} alt="Reverse Logo" className={styles.logo} />
                    </Link>
                </div>
                <nav className={styles.nav}>
                    <Link to="/diagnosis" className={styles.navLink}>예상 탕감액 진단</Link>
                    <Link to="/info" className={styles.navLink}>유용한 정보</Link>
                    <Link to="/firm" className={styles.navLink}>법무법인 찾기</Link>
                    <Link to="/community" className={styles.navLink}>커뮤니티</Link>
                    <Link to="/ai-chat" className={styles.navLink} onClick={openPreparationModal}>준비가이드</Link>
                </nav>
                <div className={styles.authContainer}>
                    {auth.isLoggedIn && auth.memberName ? (
                        <ProfileToggle auth={auth}
                                       handleLogout={handleLogout}
                                       profileImage={profileImage}
                        />
                    ) : (
                        <button onClick={toggleModal} className={styles.authLink}>로그인</button>
                    )}
                </div>
                <LoginModal isOpen={isModalOpen}
                onClose={toggleModal}
                />
            </header>
            <PreparationModal isOpen={isPreparationModalOpen} onClose={closePreparationModal}/>
        </>
    );
};

export default Header;
