import React from "react";
import {Link} from "react-router-dom";
import logoYellow from "../img/logo_yellow.png";

function FooterBlack() {
    return(
        <footer>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="hedingh3 text_align_left">
                                                <h3>Menu</h3>
                                                <ul className="menu_footer">
                                                    <li><Link to="/reverse">Home</Link></li>
                                                    <li><Link to="/pre-diagnosis">진단</Link></li>
                                                    <li><Link to="/info">유용한 정보</Link></li>
                                                    <li><Link to="/firm">법무법인 찾기</Link></li>
                                                    <li><Link to="/community">커뮤니티</Link></li>
                                                    <li><Link to="/mypage">마이페이지</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="hedingh3 text_align_left">
                                        <h3>About</h3>
                                        <p> Reverse는 쉽고 빠른 개인회생 도우미입니다. 개인회생 신청 가능성 진단에서 부터 상담까지 Reverse와 함께 시작해보세요.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-12">
                                    <div className="text_align_center">
                                        <img src={logoYellow} width="65%" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p>© 개인회생 도우미 <Link to="/reverse"> Reverse</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );

}
export default FooterBlack;