import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import FirmSearch from "../components/Firm/FirmSearch"; // FirmSearch 컴포넌트
import styles from '../components/Firm/FirmSearch.module.css'; // 스타일

const Firm = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.go-reverse.site/api/v1/law-info');
        setCards(response.data); // 서버에서 받아온 법무법인 데이터
      } catch (error) {
        console.error('법무법인 데이터 FETCH 에러:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={styles.pageContainer}>
      <NavBar activeValue="firm" />
      <main className={styles.mainContent}>
        <div className={styles.contentWrapper}>
          {/* 법무법인 검색 및 데이터 출력 */}
          <div className={styles.componentContainer}>
            <FirmSearch cards={cards} /> {/* 검색 기능에 필요한 컴포넌트 */}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Firm;
