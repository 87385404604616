import React, { useState } from 'react';
import axios from 'axios';
import styles from './FirmSearch.module.css';

const FirmSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handleSearch = async (page = 0) => {
      setIsLoading(true);
      setMessage('');
      setResults([]);
  
      try {
          const response = await axios.get('https://api.go-reverse.site/api/v1/law-info/search', {
              params: { query: searchQuery, page: page, size: 10 }
          });
          setResults(response.data.content || []);
          setCurrentPage(response.data.number || 0);
          setTotalPages(response.data.totalPages || 0);
          if (!response.data.content || response.data.content.length === 0) {
              setMessage('검색된 결과가 없습니다.');
          }
      } catch (err) {
          console.error('검색 중 에러 발생 : ', err);
          setMessage('검색 오류.');
      } finally {
          setIsLoading(false);
      }
  };

  const handlePageChange = (newPage) => {
      handleSearch(newPage);
  };

  return (
      <div className={styles.searchContainer}>
          <h2 className={styles.searchTitle}>🔍내 지역 법무법인 검색하기</h2>
          <form onSubmit={(e) => { e.preventDefault(); handleSearch(); }} className={styles.searchForm}>
              <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="법무법인 이름, 주소 또는 전화번호 검색"
                  className={styles.input}
              />
              <button type="submit" className={styles.button} disabled={isLoading}>
                  {isLoading ? '검색 중...' : '검색'}
              </button>
          </form>

          {message && <p className={styles.message}>{message}</p>}

          {Array.isArray(results) && results.length > 0 && (
  <>
      <table className={styles.resultTable}>
          <thead>
              <tr>
                  <th>법무법인</th>
                  <th>주소</th>
                  <th>연락처</th>
              </tr>
          </thead>
          <tbody>
              {results.map((result, index) => (
                  <tr key={index}>
                      <td>{result.lawName}</td>
                      <td>{result.lawAddress}</td>
                      <td>{result.lawNumber}</td>
                  </tr>
              ))}
          </tbody>
      </table>
      <div className={styles.pagination}>
          <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 0}
          >
              이전
          </button>
          <span>{currentPage + 1} / {totalPages}</span>
          <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages - 1}
          >
              다음
          </button>
      </div>
  </>
)}
      </div>
  );
};

export default FirmSearch;
