import React from "react";
import NavBar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import logoYellow from "../img/logo_yellow.png";
import FooterBlack from "../components/FooterBlack";

function Home() {
    const navigate = useNavigate();

    const toPreDiagnosis = () => {
        navigate('/pre-diagnosis');
    };

    return (
        <div className="main-layout inner_page">
            <NavBar activeValue="" />
            <div className="services">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="titlepage text_align_center ">
                                <p className="home-title">" 회생하고, 역전하자! "</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div id="ho_shad" className="services_box text_align_center">
                                <p className="home-sub-title">쉽고 빠른 개인회생 상담 Reverse</p>
                                <div className="home-logo-div">
                                    <img src={logoYellow} width="24%" alt="Logo" />
                                </div>
                                <div className="next-button">
                                    <button className="read_more"
                                        onClick={toPreDiagnosis}
                                    >📋 회생 가능성 진단하기</button>
                            </div>
                         </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterBlack />
        </div>
    );
}
export default Home;