import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import {useUserContext} from "../../UserContext";
import styles from './CommunityMain.module.css';
import {format, parseISO, isValid} from 'date-fns';

const CommunityMain = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('title');
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [isChatOpen, setIsChatOpen] = useState(false); // 채팅방 상태 추가

  const fetchPosts = async (page, term = '', type = 'title') => {
    try {
      let url = `https://api.go-reverse.site/api/v1/communities?page=${page}&size=10`;
      if (term) {
        url = `https://api.go-reverse.site/api/v1/communities/search?page=${page}&size=10&searchType=${type}&searchTerm=${encodeURIComponent(term)}`;
      }
      const response = await axios.get(url);

      if (response.data && response.data.content && Array.isArray(response.data.content)) {
        setPosts(response.data.content);
        setTotalPages(response.data.totalPages || 0);
      } else {
        setPosts([]);
        setTotalPages(0);
      }
    } catch (error) {
      console.error('게시글 fetch 오류:', error);
      setPosts([]);
    }
  };

  useEffect(() => {
    fetchPosts(currentPage);
  }, [currentPage]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchPosts(0, searchTerm, searchType);
  };

  const handleReset = () => {
    setSearchTerm('');
    setSearchType('title');
    setCurrentPage(0);
    fetchPosts(0);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchPosts(page, searchTerm, searchType);
  };

  const renderUserName = (name, role) => {
    return (
      <span>
        {name}
        {role === 'ROLE_LAWYER' && '🧑‍⚖️'}
      </span>
    );
  };

  return (
    <div className={styles.communityContainer}>
      <Link to="/community" onClick={handleReset} className={styles.communityTitle}>커뮤니티</Link>
      <div className={styles.searchContainer}>
        <select className={styles.searchSelect} value={searchType} onChange={(e) => setSearchType(e.target.value)}>
          <option value="title">제목</option>
          <option value="author">작성자</option>
        </select>
        <input
          className={styles.searchInput}
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="검색어를 입력하세요"
        />
        <button className={styles.searchButton} onClick={handleSearch}>검색</button>
        <button className={styles.resetButton} onClick={handleReset}>초기화</button>
      </div>
      {!isChatOpen && (
        <button
          className={styles.createButton}
          onClick={() => navigate('/community/create')}
          style={{ display: isChatOpen ? 'none' : 'inline-block' }}
        >
          작성하기
        </button>
      )}
      <table className={styles.postTable}>
        <thead>
          <tr>
            <th>글 번호</th>
            <th>제목</th>
            <th>작성자</th>
            <th>작성일</th>
            <th>조회수</th>
          </tr>
        </thead>
        <tbody>
          {posts && posts.length > 0 ? (
            posts.map((post) => (
              <tr key={post.postId}>
                <td>{post.displayId}</td>
                <td>
                  <Link to={`/community/${post.postId}`}>{post.title}</Link>
                </td>
                <td>{renderUserName(post.memberName, post.memberRole)}</td>
                <td>{post.createdAt}</td>
                <td>{post.viewCnt}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">게시글이 없습니다.</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className={styles.pagination}>
        {[...Array(totalPages)].map((_, i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`${styles.pageButton} ${currentPage === i ? styles.activePage : ''}`}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CommunityMain;