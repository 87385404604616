import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import styles from './PostDetail.module.css';

function PostDetail() {
  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editingContent, setEditingContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { postId } = useParams();
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    if (postId && !isNaN(postId)) {
      fetchPost();
      fetchComments();
    } else {
      setError('유효하지 않은 게시글 ID입니다.');
      setLoading(false);
    }
  }, [postId]);

  const fetchPost = async () => {
    try {
      const response = await axios.get(`https://api.go-reverse.site/api/v1/communities/${postId}`,
        {
        withCredentials: true
      });
      setPost(response.data);
      setLoading(false);
    } catch (error) {
      console.error('게시글 불러오기 실패', error);
      setError('게시글을 불러오는데 실패했습니다.');
      setLoading(false);
    }
  };

  const fetchComments = async () => {
    try {
      const response = await axios.get(
        `https://api.go-reverse.site/api/v1/communities/${postId}/comments`,
        {
          withCredentials: true
        });
      setComments(response.data.content || []);
    } catch (error) {
      console.error('댓글 불러오기 실패', error);
      setComments([]);
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!auth.isLoggedIn) {
      alert('댓글을 작성하려면 로그인이 필요합니다.');
      navigate('/login');
      return;
    }
    try {
      await axios.post(`https://api.go-reverse.site/api/v1/communities/${postId}/comments`, {
        content: newComment
      }, {
        withCredentials: true
      });
      setNewComment('');
      fetchComments();
    } catch (error) {
      console.error('댓글 작성 실패', error);
      alert('댓글 작성에 실패했습니다.');
    }
  };

  const handleCommentEdit = (commentId, content) => {
    setEditingCommentId(commentId);
    setEditingContent(content);
  };

  const handleCommentUpdate = async (commentId) => {
    try {
      await axios.put(`https://api.go-reverse.site/api/v1/communities/comments/${commentId}`, {
        content: editingContent
      },
      {
        withCredentials: true
      });
      setEditingCommentId(null);
      fetchComments();
    } catch (error) {
      console.error('댓글 수정 실패', error);
      alert('댓글 수정에 실패했습니다.');
    }
  };

  const handleCommentDelete = async (commentId) => {
    if (window.confirm('정말로 이 댓글을 삭제하시겠습니까?')) {
      try {
        await axios.delete(`https://api.go-reverse.site/api/v1/communities/comments/${commentId}`, {
          withCredentials: true
        });
        fetchComments();
      } catch (error) {
        console.error('댓글 삭제 실패', error);
        alert('댓글 삭제에 실패했습니다.');
      }
    }
  };

  const handlePostDelete = async () => {
    if (window.confirm('정말로 이 게시글을 삭제하시겠습니까?')) {
      try {
        await axios.delete(`https://api.go-reverse.site/api/v1/communities/${postId}`, {
          withCredentials: true
        });
        navigate('/community');
      } catch (error) {
        console.error('게시글 삭제 실패', error);
        alert('게시글 삭제에 실패했습니다.');
      }
    }
  };

  const renderUserName = (name, role) => {
    return (
      <span>
        {name}
        {role === 'ROLE_LAWYER' && ' 🧑‍⚖️'}
      </span>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }
  if (!post) {
    return <div>게시글을 찾을 수 없습니다.</div>;
  }

  const isPostAuthor = auth.isLoggedIn && auth.memberId === post.memberId;

  return (
    <div className={styles.postDetail}>
      <h2>{post.title}</h2>
      <p>작성자: {renderUserName(post.memberName, post.memberRole)}</p>
      <p>작성일: {post.createdAt}</p>
      <p>조회수: {post.viewCnt}</p>
      <div className={styles.postContent}>{post.content}</div>
      {isPostAuthor && (
        <div className={styles.buttons}>
          <button onClick={() => navigate(`/community/edit/${postId}`)}>수정
          </button>
          <button onClick={handlePostDelete}>삭제</button>
        </div>
      )}
      <div className={styles.comments}>
        <h3>댓글</h3>
        {comments && comments.length > 0 ? (
          comments.map((comment) => (
            <div key={comment.id} className={styles.comment}>
              {editingCommentId === comment.id ? (
                <>
                  <textarea
                    value={editingContent}
                    onChange={(e) => setEditingContent(e.target.value)}
                  />
                  <button
                  onClick={() => handleCommentUpdate(comment.id)}>저장
                  </button>
                  <button onClick={() => setEditingCommentId(null)}>취소
                  </button>
                </>
              ) : (
                <>
                  <p>{comment.content}</p>
                  <p>작성자: {renderUserName(comment.memberName,
                    comment.memberRole)}</p>
                  <p>작성일: {comment.createdAt}</p>
                  {auth.isLoggedIn && auth.memberId === comment.memberId && (
                    <>
                      <button onClick={() => handleCommentEdit(comment.id,
                        comment.content)}>수정
                        </button>
                      <button onClick={() => handleCommentDelete(
                        comment.id)}>삭제
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          ))
        ) : (
          <p>댓글이 없습니다.</p>
        )}
      </div>
      {auth.isLoggedIn ? (
        <form onSubmit={handleCommentSubmit} className={styles.commentForm}>
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="댓글을 입력하세요"
            required
          />
          <button type="submit">댓글 작성</button>
        </form>
      ) : (
        <p>댓글을 작성하려면 로그인이 필요합니다.</p>
      )}
    </div>
  );
}

export default PostDetail;