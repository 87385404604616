import React from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Home from './routes/Home';
import Diagnosis from './routes/Diagnosis';
import Info from './routes/Info';
import Firm from './routes/Firm'; 
import Community from './routes/Community';
import CreatePost from './components/Post/CreatePost';
import AiChat from './routes/AiChat';
import Signup from './routes/Signup';
import Admin from "./routes/Admin";
import Mypage from './routes/Mypage';
import AiChatIcon from './components/AiChatIcon';
import PreDiagnosis from "./routes/PreDiagnosis"
import DiagnosisResult from "./routes/DiagnosisResult"
import ScrollToTop from "./components/ScrollToTop";
import {UserProvider} from "./UserContext";
import {AuthProvider} from "./contexts/AuthContext";

function App() {
  return (<AuthProvider>
        <UserProvider>
          <BrowserRouter>
            <ScrollToTop/>
            <Routes>
              <Route path="/reverse" element={<Home/>}/>
              <Route path="/diagnosis" element={<Diagnosis/>}/>
              <Route path="/pre-diagnosis" element={<PreDiagnosis/>}/>
              <Route path="/diagnosis-result" element={<DiagnosisResult/>}/>
              <Route path="/info" element={<Info/>}/>
              <Route path="/firm" element={<Firm/>}/>
              <Route path="/community/*" element={<Community/>}/>
              <Route path="/community/create" element={<CreatePost />} />
              <Route path="/ai-chat" element={<AiChat/>}/>
              <Route path="/signup" element={<Signup/>}/>
              <Route path="/mypage" element={<Mypage/>}/>
              <Route path="/admin" element={<Admin/>}/>
              <Route path="/" element={<Navigate replace to="/reverse"/>}/>
            </Routes>
            <AiChatIcon/>
          </BrowserRouter>
        </UserProvider>
      </AuthProvider>);
}

export default App;
