import React from 'react';
import './css/LoginPromptModal.css';

const LoginPromptModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modalOverlay">
            <div className="modalContent">
                {/* <h2>로그인이 필요한 서비스입니다.</h2> */}
                <p>로그인이 필요한 서비스입니다.</p>
                <button onClick={onClose} className="closeButton">닫기</button>
            </div>
        </div>
    );
};

export default LoginPromptModal;
