import React, {createContext, useContext, useState, useEffect} from 'react';
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get('https://api.go-reverse.site/api/v1/user',
          {
          withCredentials: true, 
        }
      );

        if (response.data) {
          setUser(response.data);
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.error('로그인 상태 확인 실패', error);
        setIsLoggedIn(false);
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={{user, isLoggedIn, loading}}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);